import { supabase } from '../db/client';
import { Event } from '../db/models';

const populateEventImage = async (
  event: Event,
  prop: keyof Event,
  setter: (url: string) => void
): Promise<void> => {
  const value = event[prop];
  if (value === undefined || value === null) {
    return;
  }
  if (typeof value !== 'string') {
    return;
  }
  if (value === '') {
    return;
  }
  if (!value.startsWith('http://') && !value.startsWith('https://')) {
    const { data } = await supabase.storage
      .from('branding')
      .getPublicUrl(value.replace(/^\//, ''));
    if (data && data.publicUrl) {
      setter(data.publicUrl);
    }
  } else if (value.startsWith('http://') || value.startsWith('https://')) {
    setter(value);
  } else {
    setter('');
  }
};

export default populateEventImage;
