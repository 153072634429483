import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from './EventPicker';
import { Inner } from './AddScore';
import { GameMode, GameModeWithSkin, GameModes } from '../db/models';

interface ConfigProps {
  setGameModes: (modes: GameModeWithSkin[]) => void;
  gameModes: GameModeWithSkin[];
}

const Config = ({ setGameModes, gameModes }: ConfigProps): JSX.Element => {
  return (
    <>
      <Link to="/">
        <Button>Back to menu</Button>
      </Link>
      <Container>
        <Inner>
          <h1>Config</h1>
          <div>
            <p>Game modes</p>
            <p>(Cmd + click to select multiple)</p>
            <select
              multiple
              value={gameModes.map((m) => `${m.mode}-${m.skin ?? ''}`)}
              onChange={(e) => {
                setGameModes(
                  Array.from(e.target.selectedOptions).map((o) => {
                    const [mode, skin] = o.value.split('-');
                    return { mode: mode as GameMode, skin };
                  })
                );
              }}
            >
              {GameModes.map((m) => (
                <option
                  key={`${m.type}-${m.skin ?? ''}`}
                  value={`${m.type}-${m.skin ?? ''}`}
                >
                  {m.title}
                </option>
              ))}
            </select>
          </div>
        </Inner>
      </Container>
    </>
  );
};

export default Config;

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
`;
