import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { supabase } from '../db/client';
import {
  ArcadeStrangeScore,
  GameModeWithSkin,
  GameModes,
  Table,
} from '../db/models';
import { FadeOutGradBottom } from './BackgroundVideo';

const TABLE_NAME = Table.POUR;

const ArcadeStrangeScoreboard = ({
  eventId,
  gameModes,
  width,
  cutoff,
}: {
  width?: number;
  eventId: number;
  gameModes: GameModeWithSkin[];
  cutoff: string | null;
}): JSX.Element => {
  const [scores, setScores] = useState<ArcadeStrangeScore[]>([]);

  const fetchScores = async () => {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select('id, name, score, mode, skin, claimed')
      .eq('claimed', true)
      .eq('event_id', eventId)
      .gt('created_at', cutoff ?? '1900-01-01')
      .order('score', { ascending: false });
    setScores(data as unknown as ArcadeStrangeScore[]);
    if (error) console.error(error);
  };

  useEffect(() => {
    supabase
      .from(TABLE_NAME)
      .select('id, name, score, mode, skin, claimed')
      .order('score', { ascending: false })
      .eq('claimed', true)
      .eq('event_id', eventId)
      .gt('created_at', cutoff ?? '1900-01-01')
      .then((res) => {
        console.log(res);
        setScores(res.data as unknown as ArcadeStrangeScore[]);

        const listen = supabase
          .channel(`public:${TABLE_NAME}`)
          .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: TABLE_NAME },
            (payload) => {
              console.log('Change received!', payload);
              fetchScores();
            }
          )
          .subscribe();
        console.log(listen);
      });
  }, []);

  console.log(gameModes);
  return (
    <Outer width={width}>
      {/* <h1>HIGH SCORES</h1> */}
      <ScoreboardContainer events={gameModes.length}>
        {gameModes.map((m) => (
          <ScoreColumn key={`${m.mode}-${m.skin ?? ''}`}>
            {/* <img src="assets/MindText.png" /> */}
            <h1
              style={{
                fontSize: '36px',
                marginBottom: '8px',
                textTransform: 'uppercase',
              }}
            >
              {
                GameModes.find(
                  (g) => g.type === m.mode && (g.skin ?? '') === (m.skin ?? '')
                )?.title
              }
            </h1>
            <h1 style={{ fontSize: '24px' }}>SCORES</h1>
            <div className="score-inner">
              {scores &&
                scores
                  .filter((s) => s.mode === m.mode && s.skin === (m.skin ?? ''))
                  .map((s, i) => (
                    <ScoreItem
                      key={s.id}
                      score={s}
                      rank={i + 1}
                      unit={
                        GameModes.find(
                          (g) =>
                            g.type === m.mode &&
                            (g.skin ?? '') === (m.skin ?? '')
                        )?.units
                      }
                    />
                  ))}
            </div>
          </ScoreColumn>
        ))}
        {/* <BackgroundVideo /> */}
      </ScoreboardContainer>
      <FadeOutGradBottom />
    </Outer>
  );
};

export default ArcadeStrangeScoreboard;

const Outer = styled.div<{ width?: number }>`
  width: 100%;
  min-height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: ${(p) => (!!p.width && p.width > 0 ? p.width : '1100')}px;
  margin: 0 auto;

  h1 {
    font-family: 'Press Start 2P', monospace;
    margin-bottom: 32px;
    color: ${(p) => p.theme.colors.greyPale};
    font-size: 50px;
  }
`;

const ScoreboardContainer = styled.div<{ events?: number }>`
  width: 100%;

  display: grid;
  gap: 48px;
  grid-template-columns: ${(p) =>
    [...new Array(p.events ?? 1)].map(() => '1fr').join(' ')};

  color: ${(p) => p.theme.colors.greyPale};
`;

const ScoreItem = ({
  score,
  rank,
  unit = 'pts',
}: {
  score: ArcadeStrangeScore;
  rank: number;
  unit?: string;
}) => {
  return (
    <ScoreItemWrapper
      id={score.id.toFixed(1)}
      initial={{ color: '#EF75E0', scale: 0.95 }}
      animate={{ color: '#ffffff', scale: 1.0 }}
      transition={{ duration: 3 }}
    >
      <span className="first">{rank}</span>
      <span className="second">{score.name}</span>
      <span className="third">
        {score.score.toFixed(2)}
        <span style={{ marginRight: '2px', marginLeft: '4px', opacity: 0.8 }}>
          {unit}
        </span>
      </span>
    </ScoreItemWrapper>
  );
};

const ScoreItemWrapper = styled(motion.div)`
  width: 100%;
  font-family: 'Press Start 2P', monospace;

  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 4fr 8fr;
  padding: 4px;

  .first {
    text-align: center;
    min-width: 3ch;
  }

  .second {
    min-width: 3ch;
    text-transform: uppercase;
  }

  .third {
    min-width: 3ch;
    text-align: right;
  }
`;

const ScoreColumn = styled.div`
  width: 100%;

  max-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 50%;
  }

  .score-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }
`;
