import { useState } from 'react';
import { Session } from '@supabase/gotrue-js';
import styled from 'styled-components';

import { supabase } from '../db/client';
import { Button } from './EventPicker';

const Login = ({
  setSession,
}: {
  setSession: (s: Session) => void;
}): JSX.Element => {
  const [pw, setPw] = useState('');
  const [loading, setLoading] = useState(false);

  const signIn = async () => {
    setLoading(true);
    const res = await supabase.auth.signInWithPassword({
      email: process.env.REACT_APP_SUPABASE_LOGIN_ACCOUNT ?? '',
      password: pw,
    });

    if (res.error) setPw('');

    if (res.data.session) setSession(res.data.session);
    setLoading(false);
  };

  return (
    <Container>
      <Card>
        <input
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          placeholder="enter password"
        />
        <Button primary disabled={pw.length === 0} onClick={signIn}>
          {loading ? 'loading...' : 'Sign in'}
        </Button>
      </Card>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  display: grid;
  place-content: center;
  min-height: 100%;
`;

export const Card = styled.div`
  padding: 24px;
  background-color: ${(p) => p.theme.colors.greyPale};
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 24px;
  text-align: center;

  input {
    border-radius: 8px;
    border: 2px solid ${(p) => p.theme.colors.red};
    min-height: 32px;
    padding: 8px;
  }
`;
