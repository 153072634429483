import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { supabase } from '../db/client';
import { ArcadeStrangeScoreWithEmails, GameModes, Table } from '../db/models';
import { useEvent } from '../lib/eventProvider';
import { Button } from './EventPicker';
import { Inner } from './AddScore';
import { RealtimeChannel } from '@supabase/supabase-js';

type GameStatistics = {
  pours: number;
  claimedPours: number;
  emails: number;
  averageScore: number;
};

const calculateStatistics = (
  scores: ArcadeStrangeScoreWithEmails[]
): GameStatistics => {
  return {
    pours: scores.length,
    claimedPours: scores.filter((s) => s.claimed).length,
    emails: scores.reduce((p, s) => p + s.emails.length, 0),
    averageScore:
      scores.length > 0
        ? scores.reduce((p, s) => p + s.score, 0) / scores.length
        : 0,
  };
};

const ReportEvent = (): JSX.Element => {
  const { event } = useEvent();
  const [scores, setScores] = useState<ArcadeStrangeScoreWithEmails[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const fetchScores = useCallback(async () => {
    const { data, error } = await supabase
      .from(Table.POUR)
      .select('id, name, score, mode, skin, claimed, emails (id, email)')
      .eq('claimed', true)
      .eq('event_id', event.id)
      .gt('created_at', startDate ?? '1900-01-01')
      .lt('created_at', endDate ?? '3000-01-01')
      .order('score', { ascending: false });
    setScores(data as unknown as ArcadeStrangeScoreWithEmails[]);
    if (error) console.error(error);
  }, [event, startDate, endDate]);

  const exportEmails = useCallback(() => {
    const emails = scores.reduce<string[]>(
      (p, s) => [...p, ...s.emails.map((e) => e.email)],
      []
    );
    const csvContent = `data:text/csv;charset=utf-8,${emails.join('\n')}`;
    window.open(encodeURI(csvContent));
  }, [scores]);

  useEffect(() => {
    let listen: RealtimeChannel;
    supabase
      .from(Table.POUR)
      .select('id, name, score, mode, skin, claimed, emails (id, email)')
      .eq('event_id', event.id)
      .gt('created_at', startDate ?? '1900-01-01')
      .lt('created_at', endDate ?? '3000-01-01')
      .then((res) => {
        console.log(res);
        setScores(res.data as unknown as ArcadeStrangeScoreWithEmails[]);

        listen = supabase
          .channel(`public:${Table.POUR}`)
          .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: Table.POUR },
            (payload) => {
              console.log('Change received!', payload);
              fetchScores();
            }
          )
          .subscribe();
        console.log(listen);
      });
    return () => {
      if (listen) {
        listen.unsubscribe();
      }
    };
  }, [fetchScores, event, startDate, endDate]);

  const stats = [
    {
      label: 'Global',
      stats: calculateStatistics(scores),
    },
  ];
  for (const gameMode of GameModes) {
    const modeScores = scores.filter(
      (s) => s.mode === gameMode.type && s.skin === `${gameMode.skin ?? ''}`
    );
    if (modeScores.length > 0) {
      stats.push({
        label: gameMode.title,
        stats: calculateStatistics(modeScores),
      });
    }
  }

  return (
    <>
      <Link to="/">
        <Button>Back to menu</Button>
      </Link>
      <Container>
        <Inner>
          <h1>{event.name}</h1>
          <FilterRow>
            <label htmlFor="startDate">Start date</label>
            <input
              id="startDate"
              type="datetime-local"
              value={startDate ?? undefined}
              onChange={(e) =>
                setStartDate(e.target.value === '' ? null : e.target.value)
              }
            />
            <label htmlFor="endDate">End date</label>
            <input
              id="endDate"
              type="datetime-local"
              value={endDate ?? undefined}
              onChange={(e) =>
                setEndDate(e.target.value === '' ? null : e.target.value)
              }
            />
          </FilterRow>
          {stats.map((s) => (
            <StatRow key={s.label}>
              <StatRowLabel>{s.label}</StatRowLabel>
              <StatRowStat>
                <StatRowNumber>{s.stats.pours}</StatRowNumber>
                {s.stats.pours !== 1 ? 'pours' : 'pour'}
              </StatRowStat>
              <StatRowStat>
                <StatRowNumber>{s.stats.claimedPours}</StatRowNumber> claimed
              </StatRowStat>
              <StatRowStat
                onClick={s.label === 'Global' ? exportEmails : undefined}
              >
                <StatRowNumber>{s.stats.emails}</StatRowNumber>
                {s.stats.emails !== 1 ? 'emails' : 'email'}
              </StatRowStat>
              <StatRowStat>
                <StatRowNumber>{s.stats.averageScore.toFixed(2)}</StatRowNumber>
                average
              </StatRowStat>
            </StatRow>
          ))}
        </Inner>
      </Container>
    </>
  );
};

const FilterRow = styled.div``;

const StatRow = styled.div`
  display: grid;
  width: 75vmin;
  margin: 25px 0;
  grid-template-areas:
    'label label label label'
    'pours claimed emails average';
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const StatRowLabel = styled.div`
  grid-area: label;
`;

const StatRowStat = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const StatRowNumber = styled.div`
  font-size: 7vmin;
  color: #ed027e;
`;

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: grid;
  place-content: center;

  > div {
    overflow: scroll;
  }

  img {
    max-width: 30vw;
  }

  input[type='checkbox'] {
    width: 32px;
    display: block;
  }
`;

export default ReportEvent;
