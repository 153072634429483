import { motion } from 'framer-motion';

export const BlurASLogo = (): JSX.Element => (
  <motion.svg
    // width="495"
    // height="146"
    viewBox="0 0 495 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.path
      stroke="#FF52E3"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 1.4 }}
      style={{ filter: 'blur(4px)' }}
      d="M3.82227 67.665C69.1873 67.665 13.2573 67.665 78.9623 67.665L41.3923 4L3.82227 67.665ZM130.829 8.165H102.269C102.269 54.15 102.269 1.78999 102.269 67.75C119.694 67.75 137.119 67.75 154.544 67.75L145.789 51.345C151.484 46.925 155.054 40.125 155.054 32.39C155.054 18.96 144.174 8.165 130.829 8.165ZM189.488 15.73C177.248 27.97 177.248 47.775 189.488 60.015C201.728 72.255 221.533 72.255 233.773 60.015L211.588 37.83L233.773 15.73C221.533 3.49 201.728 3.49 189.488 15.73ZM254.738 67.665C320.103 67.665 264.173 67.665 329.878 67.665L292.308 4L254.738 67.665ZM382.935 67.75C399.34 67.75 412.685 54.405 412.685 38C412.685 21.595 399.34 8.165 382.935 8.165H353.185C353.185 69.025 353.185 6.89 353.185 67.75H382.935ZM440.059 8.08V67.58H489.359V47.52H477.034V44.97H489.359V30.69H477.034V28.14H489.359V8.08H440.059ZM17.845 89.565C10.837 89.565 5.14304 95.697 5.14304 102.267C5.14304 108.91 9.59604 113.363 16.75 114.75V115.261H6.45704V140.738H44.49C59.236 140.738 61.937 119.057 47.264 115.772V115.261H56.17V89.565H17.845ZM115.059 115.188H124.987V89.565H78.9244V115.188H89.5094V140.738H115.059V115.188ZM173.304 89.565H148.776C148.776 129.058 148.776 84.09 148.776 140.738C163.741 140.738 178.706 140.738 193.671 140.738L186.152 126.649C191.043 122.853 194.109 117.013 194.109 110.37C194.109 98.836 184.765 89.565 173.304 89.565ZM212.951 140.665C269.088 140.665 221.054 140.665 277.483 140.665L245.217 85.988L212.951 140.665ZM297.499 88.324V140.665C344.584 140.665 301.514 140.665 348.599 140.665V89.565H323.195V108.399L297.499 88.324ZM398.664 115.042L412.169 91.609C406.767 88.543 400.416 87.375 394.357 88.324C381.436 90.441 371.654 101.61 371.654 115.042C371.654 130.007 383.699 142.052 398.664 142.052C413.629 142.052 425.674 130.007 425.674 115.042H398.664ZM448.787 89.492V140.592H491.127V123.364H480.542V121.174H491.127V108.91H480.542V106.72H491.127V89.492H448.787Z"
      strokeWidth="7"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <motion.path
      stroke="#FFE3FA"
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 8 }}
      d="M3.82227 67.665C69.1873 67.665 13.2573 67.665 78.9623 67.665L41.3923 4L3.82227 67.665ZM130.829 8.165H102.269C102.269 54.15 102.269 1.78999 102.269 67.75C119.694 67.75 137.119 67.75 154.544 67.75L145.789 51.345C151.484 46.925 155.054 40.125 155.054 32.39C155.054 18.96 144.174 8.165 130.829 8.165ZM189.488 15.73C177.248 27.97 177.248 47.775 189.488 60.015C201.728 72.255 221.533 72.255 233.773 60.015L211.588 37.83L233.773 15.73C221.533 3.49 201.728 3.49 189.488 15.73ZM254.738 67.665C320.103 67.665 264.173 67.665 329.878 67.665L292.308 4L254.738 67.665ZM382.935 67.75C399.34 67.75 412.685 54.405 412.685 38C412.685 21.595 399.34 8.165 382.935 8.165H353.185C353.185 69.025 353.185 6.89 353.185 67.75H382.935ZM440.059 8.08V67.58H489.359V47.52H477.034V44.97H489.359V30.69H477.034V28.14H489.359V8.08H440.059ZM17.845 89.565C10.837 89.565 5.14304 95.697 5.14304 102.267C5.14304 108.91 9.59604 113.363 16.75 114.75V115.261H6.45704V140.738H44.49C59.236 140.738 61.937 119.057 47.264 115.772V115.261H56.17V89.565H17.845ZM115.059 115.188H124.987V89.565H78.9244V115.188H89.5094V140.738H115.059V115.188ZM173.304 89.565H148.776C148.776 129.058 148.776 84.09 148.776 140.738C163.741 140.738 178.706 140.738 193.671 140.738L186.152 126.649C191.043 122.853 194.109 117.013 194.109 110.37C194.109 98.836 184.765 89.565 173.304 89.565ZM212.951 140.665C269.088 140.665 221.054 140.665 277.483 140.665L245.217 85.988L212.951 140.665ZM297.499 88.324V140.665C344.584 140.665 301.514 140.665 348.599 140.665V89.565H323.195V108.399L297.499 88.324ZM398.664 115.042L412.169 91.609C406.767 88.543 400.416 87.375 394.357 88.324C381.436 90.441 371.654 101.61 371.654 115.042C371.654 130.007 383.699 142.052 398.664 142.052C413.629 142.052 425.674 130.007 425.674 115.042H398.664ZM448.787 89.492V140.592H491.127V123.364H480.542V121.174H491.127V108.91H480.542V106.72H491.127V89.492H448.787Z"
      strokeWidth="7"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </motion.svg>
);
