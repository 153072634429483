import { Event } from '../db/models';
import EventForm from './EventForm';

const EditEvent = ({
  event,
  onSave,
}: {
  event: Event;
  onSave: () => void;
}): JSX.Element => <EventForm event={event} onSave={onSave} />;

export default EditEvent;
