// FIXME: these models need cleaning up - many are unused

export type GameMode = 'Mind' | 'Body' | 'Race' | 'Safe';

export type GameModeWithSkin = {
  mode: GameMode;
  skin?: string;
};

export interface Mode {
  type: GameMode;
  title: string;
  units?: string;
  skin?: string;
}

export const GameModes: Mode[] = [
  {
    type: 'Mind',
    title: 'Mind',
  },
  {
    type: 'Body',
    title: 'Body',
  },
  {
    type: 'Safe',
    title: 'Safe',
  },
  {
    type: 'Race',
    title: 'Drive',
    units: 'm',
    skin: 'road',
  },
  {
    type: 'Race',
    title: 'Space',
    units: 'm',
    skin: 'space',
  },
];

export interface Event {
  id: number;
  name: string;
  image_path: string;
  beer_icon_path: string;
  leaderboard_image_path: string;
  leaderboard_background_path: string;
  created_at: string;
  beer_icon_size: number | null;
  leaderboard_margin_top: number | null;
  leaderboard_width: number | null;
  body_left_image_path: string;
  body_right_image_path: string;
  body_image_tint: boolean;
  leaderboard_cut_off: string | null;
  legacy: boolean;
}

export interface ScoreTable {
  id: number;
  created_at: string;
  name: string;
  score: number;
  mode: string;
  event_id: number;
}

export interface PourTable {
  id: number;
  created_at: string;
  score: number;
  completed: boolean;
  mode: GameMode;
  name: string;
  difficulty: 'easy' | 'medium' | 'hard';
  account_id: string;
  event_id: string;
  claimed: boolean;
}

export interface Score {
  id: number;
  name: string;
  score: number;
  mode: string;
  event_id: string;
}

export interface ArcadeStrangeScore {
  id: number;
  name: string;
  score: number;
  mode: GameMode;
  claimed: boolean;
  skin: string;
}

export interface ArcadeStrangeScoreWithEmails extends ArcadeStrangeScore {
  emails: {
    id: string;
    email: string;
  }[];
}

export interface EmailTable {
  email: string;
  pour_id: number;
}

export enum Table {
  SCORE = 'score_test',
  EVENT = 'event',
  POUR = 'pours',
  EMAIL = 'emails',
}

//force
