import { useNavigate } from 'react-router-dom';
import EventForm from './EventForm';

const CreateEvent = (): JSX.Element => {
  const navigate = useNavigate();

  return <EventForm event={null} onSave={(e) => navigate(`/edit/${e.id}`)} />;
};

export default CreateEvent;
