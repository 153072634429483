import { motion } from 'framer-motion';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Score } from '../db/models';

interface ScoreCardProps {
  score: Score;
  rank: number;
}

const ScoreCard = ({ score, rank }: ScoreCardProps): JSX.Element => {
  const stringId = score.id.toString();
  const formatName = useCallback(
    (name: string) => {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    [score.name]
  );
  return (
    <ScoreCardContainer id={stringId} layoutId={stringId}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Rank>{rank}</Rank>
        <ScoreInfo>
          <h1>{formatName(score.name)}</h1>
          <p>{score.score} points</p>
        </ScoreInfo>
      </div>
      <Mode>{score.mode}</Mode>
    </ScoreCardContainer>
  );
};

export default ScoreCard;

const ScoreCardContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 800px;
  padding: 32px;

  border-radius: 24px;

  background-color: ${(p) => p.theme.colors.greyPale};
`;

const Rank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 56px;

  border-radius: 8px;

  background-color: ${(p) => p.theme.colors.gold};
  color: ${(p) => p.theme.colors.greyPale};

  font-size: 40px;
  font-weight: 700;
`;

const ScoreInfo = styled.div`
  margin-left: 24px;
  h1 {
    color: ${(p) => p.theme.colors.red};
  }
`;

const Mode = styled.p`
  align-self: flex-end;
`;
