import { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Session } from '@supabase/gotrue-js';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import GlobalStyle from './styles/GlobalStyle';
import appTheme from './styles/theme';

import Scoreboard from './components/Scoreboard';
import EventPicker from './components/EventPicker';
import Login from './components/Login';
import ClaimScore from './components/clients/ClaimScore';
import PourScores from './components/clients/PourScores';

import { QueryProvider } from './db/reactQueryClient';
import { Event, GameMode, GameModeWithSkin } from './db/models';
import { supabase } from './db/client';
import ArcadeStrangeScoreboard from './components/ArcadeStrangeScoreboard';
import { isVideoPath } from './utils';
import AddScore from './components/AddScore';
import CreateEvent from './components/CreateEvent';
import useLocalStorage from './lib/useLocalStorage';
import Config from './components/Config';
import { useQueryClient } from 'react-query';
import EditEvent from './components/EditEvent';
import populateEventImage from './lib/populateEventImage';
import { EventProvider, useEvent } from './lib/eventProvider';
import ReportEvent from './components/ReportEvent';

function App(): JSX.Element {
  const [authSession, setAuthSession] = useState<Session | null>(null);
  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [gameModes, setGameModes] = useLocalStorage<GameModeWithSkin[]>(
    'leaderboard-game-mode-skins',
    [{ mode: 'Mind' }, { mode: 'Body' }]
  );

  useEffect(() => {
    supabase.auth
      .getSession()
      .then((data) => setAuthSession(data.data.session));

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        console.log(session);
        setAuthSession(session);
      }
    );

    return () => authListener?.subscription.unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <QueryProvider>
        <ThemeProvider theme={appTheme}>
          <Container>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    <ScoreComp
                      authSession={authSession}
                      event={event}
                      setAuthSession={setAuthSession}
                      setEvent={setEvent}
                      gameModes={gameModes}
                    />
                  </AuthWrapper>
                }
              />
              <Route
                path="add"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    {authSession ? (
                      <AddScore authSession={authSession} />
                    ) : (
                      <></>
                    )}
                  </AuthWrapper>
                }
              />
              <Route
                path="create"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    <CreateEvent />
                  </AuthWrapper>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    <EventProvider>
                      <EditEventView />
                    </EventProvider>
                  </AuthWrapper>
                }
              />
              <Route
                path="report/:id"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    <EventProvider>
                      <ReportEvent />
                    </EventProvider>
                  </AuthWrapper>
                }
              />
              <Route
                path="config"
                element={
                  <AuthWrapper
                    authSession={authSession}
                    setAuthSession={setAuthSession}
                  >
                    <Config gameModes={gameModes} setGameModes={setGameModes} />
                  </AuthWrapper>
                }
              />
              <Route
                path="board/:id"
                element={
                  <EventProvider>
                    <BoardView gameModes={gameModes} />
                  </EventProvider>
                }
              />
              <Route path="claim/:id" element={<ClaimScore />} />
              <Route
                path="score/:id"
                element={
                  <EventProvider>
                    <PourScores gameModes={gameModes} />
                  </EventProvider>
                }
              />
            </Routes>
          </Container>
          <Toaster position="bottom-center" />
          <GlobalStyle />
        </ThemeProvider>
      </QueryProvider>
    </BrowserRouter>
  );
}

export default App;

interface ScoreCompProps {
  authSession: Session | null;
  event: Event | undefined;
  setAuthSession: (e: Session | null) => void;
  setEvent: (ev: Event | undefined) => void;
  gameModes: GameModeWithSkin[];
}

interface AuthWrapperProps {
  authSession: Session | null;
  setAuthSession: (e: Session | null) => void;
  children: React.ReactChild;
}

const AuthWrapper = ({
  authSession,
  setAuthSession,
  children,
}: AuthWrapperProps): JSX.Element => {
  if (authSession) {
    return <>{children}</>;
  }
  return (
    <>
      <Login setSession={setAuthSession} />
    </>
  );
};

const ScoreComp = ({ event, gameModes }: ScoreCompProps) => {
  return (
    <>
      {event ? (
        <ScoreView event={event} gameModes={gameModes} />
      ) : (
        <>
          <EventPicker />
        </>
      )}
    </>
  );
};

const Container = styled.div`
  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
`;

const Logo = styled.img`
  max-width: 800px;
  max-height: 40vh;
  width: auto;
  padding: 32px 8px;
`;

const VideoLogo = styled.video`
  width: 100%;
  max-width: 720px;
  padding: 32px 8px;
`;

interface ScoreViewProps {
  event: Event;
  gameModes: GameModeWithSkin[];
}

interface BoardViewProps {
  gameModes: GameModeWithSkin[];
}

const EditEventView = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const { event } = useEvent();

  const onSave = () => {
    queryClient.invalidateQueries(`event-${params.id}`);
  };

  return <EditEvent event={event} onSave={onSave} />;
};

const BoardView = ({ gameModes }: BoardViewProps) => {
  const { event } = useEvent();

  return <ScoreView event={event} gameModes={gameModes} />;
};

const ScoreView = ({ event, gameModes }: ScoreViewProps) => {
  const [leaderboardImage, setLeaderboardImage] = useState<string>();
  const [leaderboardBackgroundImage, setLeaderboardBackgroundImage] =
    useState<string>();

  useEffect(() => {
    async function getImage() {
      await populateEventImage(
        event,
        'leaderboard_image_path',
        setLeaderboardImage
      );
      await populateEventImage(
        event,
        'leaderboard_background_path',
        setLeaderboardBackgroundImage
      );
    }
    void getImage();
  }, []);

  return (
    <ScoreboardContainer
      background={leaderboardBackgroundImage}
      margin={event.leaderboard_margin_top}
    >
      <ScoreboardLogo>
        {leaderboardImage ? (
          isVideoPath(leaderboardImage) ? (
            <VideoLogo src={leaderboardImage} muted autoPlay loop />
          ) : (
            <Logo src={leaderboardImage} />
          )
        ) : null}
      </ScoreboardLogo>
      {!event.legacy ? (
        <ArcadeStrangeScoreboard
          eventId={event.id}
          gameModes={gameModes}
          width={event.leaderboard_width ?? 0}
          cutoff={event.leaderboard_cut_off}
        />
      ) : (
        <Scoreboard eventId={event.id} />
      )}
    </ScoreboardContainer>
  );
};

const ScoreboardLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreboardContainer = styled.div<{
  background?: string | null;
  margin?: number | null;
}>`
  ${(p) => (p.margin ? `padding-top: ${p.margin}px;` : '')}
  ${(p) =>
    p.background
      ? `
    background-image: url(${p.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    width: 100vw;
    min-height: 100vh;
    margin-top: -32px;
  `
      : ''}
`;
