import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { supabase } from '../db/client';
import { Event, Table } from '../db/models';

interface EventState {
  event: Event;
}

const EventContext = createContext({} as EventState);

export const useEvent = (): EventState => useContext(EventContext);

export const EventProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const params = useParams();

  const event = useQuery(
    `event-${params.id}`,
    async (): Promise<Event | null> => {
      const { data, error } = await supabase
        .from(Table.EVENT)
        .select('*')
        .eq('id', params.id)
        .order('created_at', {
          ascending: false,
        });
      if (error) throw new Error(error.message);
      if (data === null || data.length === 0) throw new Error('Bad event');
      return data[0];
    }
  );

  return event.data ? (
    <EventContext.Provider value={{ event: event.data }}>
      {children}
    </EventContext.Provider>
  ) : (
    <></>
  );
};
