import { useQuery } from 'react-query';
import styled from 'styled-components';
import { supabase } from '../../db/client';
import {
  Event,
  GameMode,
  GameModeWithSkin,
  GameModes,
  Table,
} from '../../db/models';

import { BlurASLogo } from './ArcadeStangeLogo';
import { useEvent } from '../../lib/eventProvider';

const fetchScores = async (
  mode: GameMode,
  skin: string,
  eventId: string,
  cutoff: string | null = null
): Promise<ScoreData[] | null> => {
  const { data, error } = await supabase
    .from(Table.POUR)
    .select('score, name')
    .order('score', { ascending: false })
    .limit(5)
    .match({ mode: mode, claimed: true, skin })
    .eq('event_id', eventId)
    .gt('created_at', cutoff ?? '1900-01-01');

  if (error) throw new Error(error.message);

  return data as ScoreData[];
};

interface PourScoresProps {
  gameModes: GameModeWithSkin[];
}

const PourScores = ({ gameModes }: PourScoresProps): JSX.Element => {
  const { event } = useEvent();

  return (
    <Container>
      <div style={{ maxWidth: '240px', width: '100%' }}>
        <BlurASLogo />
      </div>
      <p>This week&apos;s top scores</p>
      {gameModes.map((m) => (
        <Scores
          type={m.mode}
          key={`${m.mode}-${m.skin ?? ''}`}
          skin={m.skin}
          event={event}
        />
      ))}
    </Container>
  );
};

export default PourScores;

const Container = styled.div`
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-top: 32px;
  /* justify-content: space-evenly; */

  p {
    color: white;
  }
`;

interface ScoreData {
  name: string;
  score: number;
}

interface ScoresProps {
  event: Event;
  skin?: string;
  type: GameMode;
}

const ScoreContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

const ScoreRow = styled.div`
  color: white;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  .pos {
    grid-column: 1/2;
  }

  .name {
    grid-column: 2/3;
  }

  .score {
    grid-column: 5/6;
  }
`;

const Scores = ({ type, event, skin }: ScoresProps) => {
  const { data } = useQuery(`${type}-scores`, () =>
    fetchScores(type, skin ?? '', `${event.id}`, event.leaderboard_cut_off)
  );

  return (
    <ScoreContainer>
      <Heading
        title={
          GameModes.find(
            (g) => g.type === type && (g.skin ?? '') === (skin ?? '')
          )?.title ?? type
        }
      />
      <>
        {data ? (
          data.map((item, index) => (
            <ScoreRow key={`${index}${item.name}`}>
              <span className="pos">{index + 1}</span>
              <span className="name">{item.name}</span>
              <span className="score">{item.score}</span>
            </ScoreRow>
          ))
        ) : (
          <p>...</p>
        )}
      </>
    </ScoreContainer>
  );
};

const Heading = ({ title }: { title: string }) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
      gap: '12px',
    }}
  >
    <div
      style={{
        height: '2px',
        background: 'linear-gradient(to left, #f97dff, transparent)',
        width: '100%',
      }}
    />
    <p style={{ textTransform: 'uppercase' }}>{title}</p>
    <div
      style={{
        height: '2px',
        background: 'linear-gradient(to right, #f97dff, transparent)',
        width: '100%',
      }}
    />
  </div>
);
