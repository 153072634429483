import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { supabase } from '../db/client';

import { Button } from './EventPicker';
import { Inner } from './AddScore';
import toast from 'react-hot-toast';
// import { totalmem } from 'os';
import { Event, Table } from '../db/models';
import populateEventImage from '../lib/populateEventImage';

enum FileType {
  image_path = 'image_path',
  beer_icon_path = 'beer_icon_path',
  leaderboard_image_path = 'leaderboard_image_path',
  leaderboard_background_path = 'leaderboard_background_path',
  body_left_image_path = 'body_left_image_path',
  body_right_image_path = 'body_right_image_path',
}

type Files = {
  [s in FileType]?: File;
};

type FilePaths = {
  [s in FileType]?: string;
};

type ClearFiles = {
  [s in FileType]?: boolean;
};

const EventForm = ({
  event,
  onSave,
}: {
  event: Event | null;
  onSave: (e: Event) => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(event?.name);
  const [legacy, setLegacy] = useState<boolean>(event?.legacy ?? false);
  const [bodyImageTint, setBodyImageTint] = useState<boolean>(
    event?.body_image_tint ?? false
  );
  const [beerIconSize, setBeerIconSize] = useState(event?.beer_icon_size);
  const [leaderboardMarginTop, setLeaderboardMarginTop] = useState(
    event?.leaderboard_margin_top
  );
  const [leaderboardWidth, setLeaderboardWidth] = useState(
    event?.leaderboard_width
  );
  const [leaderboardCutoff, setLeaderboardCutoff] = useState(
    event?.leaderboard_cut_off
      ? new Date(event?.leaderboard_cut_off).toJSON().slice(0, 19)
      : null
  );
  const [files, setFiles] = useState<Files>({});
  const [clearFiles, setClearFiles] = useState<ClearFiles>({});
  const [existingFiles, setExistingFiles] = useState<FilePaths>({});

  useEffect(() => {
    async function getImage() {
      if (!event) {
        return;
      }
      setExistingFiles({});
      for (const key of Object.values(FileType)) {
        await populateEventImage(event, key, (v: string) =>
          setExistingFiles((f) => ({ ...f, [key]: v }))
        );
      }
    }
    void getImage();
  }, [event]);

  const onFileUpload = (
    name: FileType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    setFiles((f) => ({ ...f, [name]: file }));
  };

  const onClearChange = (name: FileType, value: boolean) => {
    setClearFiles((f) => ({ ...f, [name]: value }));
  };

  const uploadFile = async (
    _file: File,
    _name: string
  ): Promise<string | undefined> => {
    const { data, error } = await supabase.storage
      // If the score systems are to be combined, then "branding" can be used here
      .from('branding/images')
      .upload(`${new Date().getTime()}_${_name}`, _file);
    if (error) {
      toast.error(error.message);
      console.error;
    } else if (data && data.path) {
      toast.success('Image uploaded');
      return data.path;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    if (name) {
      const upload: FilePaths = {};
      for (const key of Object.values(FileType)) {
        if (clearFiles[key] === true) {
          upload[key] = '';
          continue;
        }
        const file = files[key];
        upload[key] = file ? await uploadFile(file, file.name) : '';
        upload[key] = upload[key]
          ? `images/${upload[key]}`
          : event
          ? event[key]
          : '';
      }
      const upsertData = {
        name,
        beer_icon_size: beerIconSize,
        leaderboard_margin_top: leaderboardMarginTop,
        leaderboard_width: leaderboardWidth,
        leaderboard_cut_off: leaderboardCutoff,
        legacy,
        body_image_tint: bodyImageTint,
        ...upload,
      };
      const { data, error } = event
        ? await supabase
            .from(Table.EVENT)
            .update(upsertData)
            .eq('id', event.id)
            .select<'*', Event>()
        : await supabase
            .from(Table.EVENT)
            .insert(upsertData)
            .select<'*', Event>();
      if (error) toast.error(error.message);
      else if (data) {
        toast.success(
          event ? `Updated event for ${name}` : `Created event for ${name}`
        );
        console.log(data[0]);
      }
      setFiles({});
      setClearFiles({});
      const newEvent = data ? data[0] : null;
      if (newEvent) {
        onSave(newEvent);
      }
    } else {
      toast.error('Ensure all fields are filled');
    }
    setLoading(false);
  };

  return (
    <>
      <Link to="/">
        <Button>Back to menu</Button>
      </Link>
      <Container>
        <Inner>
          <h1>{event ? 'Edit' : 'Create'} event</h1>
          <div>
            <p>Event name</p>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Event Name"
            />
          </div>

          <div>
            <label htmlFor="legacy">Legacy event</label>
            <input
              id="legacy"
              type="checkbox"
              checked={legacy}
              onChange={(e) => setLegacy(e.target.checked)}
            />
          </div>

          <ImageUpload
            label="Event image"
            name={FileType.image_path}
            clear={clearFiles[FileType.image_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.image_path]}
          />
          <ImageUpload
            label="Beer icon"
            name={FileType.beer_icon_path}
            clear={clearFiles[FileType.beer_icon_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.beer_icon_path]}
          />

          <div>
            <p>Beer icon size</p>
            <input
              type="number"
              min="0"
              value={beerIconSize || ''}
              onChange={(e) =>
                setBeerIconSize(
                  e.target.value === '' ? null : e.target.valueAsNumber
                )
              }
              placeholder="Default is 120"
            />
          </div>

          <ImageUpload
            label="Body left 'dot' icon"
            name={FileType.body_left_image_path}
            clear={clearFiles[FileType.body_left_image_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.body_left_image_path]}
          />
          <ImageUpload
            label="Body right 'dot' icon"
            name={FileType.body_right_image_path}
            clear={clearFiles[FileType.body_right_image_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.body_right_image_path]}
          />
          <div>
            <label htmlFor="bodyImageTint">Tint dot images?</label>
            <input
              id="bodyImageTint"
              type="checkbox"
              checked={bodyImageTint}
              onChange={(e) => setBodyImageTint(e.target.checked)}
            />
          </div>

          <ImageUpload
            label="Leaderboard icon"
            name={FileType.leaderboard_image_path}
            clear={clearFiles[FileType.leaderboard_image_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.leaderboard_image_path]}
          />
          <ImageUpload
            label="Leaderboard background image"
            name={FileType.leaderboard_background_path}
            clear={clearFiles[FileType.leaderboard_background_path] ?? false}
            onClearChange={onClearChange}
            onChange={onFileUpload}
            image={existingFiles[FileType.leaderboard_background_path]}
          />

          <div>
            <p>Leaderboard margin top</p>
            <input
              type="number"
              min="0"
              value={leaderboardMarginTop || ''}
              onChange={(e) =>
                setLeaderboardMarginTop(
                  e.target.value === '' ? null : e.target.valueAsNumber
                )
              }
              placeholder="Default is 0"
            />
          </div>

          <div>
            <p>Leaderboard width</p>
            <input
              type="number"
              min="0"
              value={leaderboardWidth || ''}
              onChange={(e) =>
                setLeaderboardWidth(
                  e.target.value === '' ? null : e.target.valueAsNumber
                )
              }
              placeholder="Default is 1110"
            />
          </div>

          <div>
            <p>Leaderboard date cutoff</p>
            <input
              type="datetime-local"
              value={leaderboardCutoff ?? undefined}
              onChange={(e) =>
                setLeaderboardCutoff(
                  e.target.value === '' ? null : e.target.value
                )
              }
            />
          </div>

          <Button primary onClick={onSubmit}>
            {loading ? 'submitting...' : 'Submit'}
          </Button>
        </Inner>
      </Container>
    </>
  );
};

export default EventForm;

const ImageUpload = ({
  name,
  label,
  clear,
  image,
  onClearChange,
  onChange,
}: {
  name: FileType;
  label: string;
  clear?: boolean;
  image: string | undefined;
  onClearChange?: (name: FileType, v: boolean) => void;
  onChange: (name: FileType, e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      <p>{label}</p>
      <input
        type="file"
        name={name}
        accept="image/png, image/jpeg, image/svg+xml, image/gif, image/webp"
        onChange={(e) => onChange(name, e)}
      />
      {clear !== undefined && onClearChange !== undefined && (
        <>
          <label htmlFor={`clear-${name}`}>Clear image?</label>
          <input
            type="checkbox"
            checked={clear}
            onChange={(e) => onClearChange(name, e.target.checked)}
            id={`clear-${name}`}
          />
        </>
      )}
      {image ? <img src={image} /> : null}
    </div>
  );
};

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: grid;
  place-content: center;

  > div {
    overflow: scroll;
  }

  img {
    max-width: 30vw;
  }

  input[type='checkbox'] {
    width: 32px;
    display: block;
  }
`;
