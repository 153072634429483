import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from 'react-query';

import { supabase } from '../../db/client';
import { Table } from '../../db/models';
import useQuery from '../../lib/useQuery';

import { BlurASLogo } from './ArcadeStangeLogo';
import { Input, Button } from './FormComponents';
import useFetchPourRecord from '../../lib/useFetchPourRecord';

interface ScoreDetails {
  name: string;
  email: string;
}

const initialState: ScoreDetails = {
  name: '',
  email: '',
};

interface ScoreParams {
  id: number;
  name: string;
  email?: string;
}

export const MAX_LENGTH = 6;

const updateScore = async (id: number, name: string, email?: string) => {
  const { error } = await supabase
    .from(Table.POUR)
    .update({ name: name, claimed: true })
    .eq('id', id)
    .select();
  if (error) throw new Error(error.message);

  if (email && email.length > 0) {
    const { error: emailError } = await supabase
      .from(Table.EMAIL)
      .insert({ email, pour_id: id });

    if (emailError) throw new Error(emailError.message);
  }
};

const ClaimScore = (): JSX.Element => {
  const [state, setState] = useState(initialState);
  const params = useParams();
  const query = useQuery();
  const score = query.get('score');
  const pos = query.get('pos');
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    ({ id, name, email }: ScoreParams) => updateScore(id, name, email)
  );
  // fetch score and position
  const navigate = useNavigate();
  const nameRef = useRef<HTMLInputElement | null>(null);

  const id = params.id ? parseInt(params.id) : -1;
  const pourRecord = useFetchPourRecord({ id });

  useEffect(() => {
    if (nameRef.current) nameRef.current.focus();
  }, []);

  useEffect(() => {
    if (pourRecord.data) {
      if (pourRecord.data.claimed === true) {
        navigate(`/score/${pourRecord.data.event_id}`);
      }
    }
  }, [pourRecord]);

  useEffect(() => {
    if (isSuccess && pourRecord.data) {
      navigate(`/score/${pourRecord.data.event_id}`);
    }
  }, [isSuccess, pourRecord]);

  const handleSave = async () => {
    if (id >= 0) {
      await mutateAsync({ id, name: state.name, email: state.email });
    }
  };

  return (
    <Container>
      <LogoContainer>
        <BlurASLogo />
      </LogoContainer>
      <FormContainer>
        {score && <h2>You had a score of {score}!</h2>}
        {pos && parseInt(pos) > 0 && <p>You&apos;re currently ranked {pos}</p>}

        <>
          <p>Enter your name to display your score on the leaderboard</p>
          <NameInput
            ref={nameRef}
            placeholder={'_'.repeat(MAX_LENGTH)}
            value={state.name}
            autoFocus
            onChange={(e) =>
              setState({
                ...state,
                name: e.target.value.toUpperCase().slice(0, MAX_LENGTH),
              })
            }
          />
          <Input
            type="text"
            placeholder="email (optional)"
            value={state.email}
            autoComplete="email"
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
          <Button
            disabled={isLoading || isSuccess || state.name.length === 0}
            onClick={handleSave}
          >
            {isLoading ? 'Saving...' : 'Submit'}
          </Button>
        </>
      </FormContainer>
    </Container>
  );
};

export default ClaimScore;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  p {
    color: white;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 240px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NameInput = styled.input`
  all: unset;
  font-family: 'Press Start 2P';
  width: ${MAX_LENGTH}ch;
  font-size: ${30 / MAX_LENGTH}ch;
  max-width: 100%;
  align-self: center;
  color: white;
`;
