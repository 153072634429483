import { DefaultTheme } from 'styled-components';

const appTheme: DefaultTheme = {
  colors: {
    bg: '#000',
    red: '#BC2531',
    gold: '#F9B343',
    goldPale: '#F3D596',
    greyPale: '#EAEBE9',
    grey: '#655D58',
    neonBlue: '#6FFFF6',
    neonPink: '#EF75E0',
  },
};

export default appTheme;
