import { useQuery, UseQueryResult } from 'react-query';
import { supabase } from '../db/client';
import { PourTable, Table } from '../db/models';

const fetchPourRecord = async (id: number): Promise<PourTable | null> => {
  const { data, error } = await supabase
    .from(Table.POUR)
    .select('*')
    .eq('id', id)
    .single();

  if (error) throw new Error(error.message);

  return data;
};

interface UseFetchPourParams {
  id: number;
}

const useFetchPourRecord = ({
  id,
}: UseFetchPourParams): UseQueryResult<PourTable | null, Error> => {
  return useQuery(['pour-record', id], () => fetchPourRecord(id), {
    enabled: id > 0,
  });
};

export default useFetchPourRecord;
