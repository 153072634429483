import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { supabase } from '../db/client';
import { Event, Table } from '../db/models';

const fetchEvents = async (): Promise<Event[] | null> => {
  const { data, error } = await supabase
    .from(Table.EVENT)
    .select('*')
    .order('created_at', {
      ascending: false,
    });
  if (error) throw new Error(error.message);
  return data;
};

const EventPicker = (): JSX.Element => {
  const events = useQuery('event', fetchEvents);

  if (events.data) {
    return (
      <Container>
        <p>View, create, or edit a scoreboard</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: '100%',
            gap: '24px',
            marginBottom: '24px',
          }}
        >
          <EventCardContainer>
            <h1 style={{ marginBottom: '12px' }}>Create new event</h1>
            <Link to="/create">Create</Link>
          </EventCardContainer>
          <EventCardContainer>
            <h1 style={{ marginBottom: '12px' }}>Manually insert score</h1>
            <Link to="/add">Add score</Link>
          </EventCardContainer>
          <EventCardContainer>
            <h1 style={{ marginBottom: '12px' }}>Configure leaderboard</h1>
            <Link to="/config">Config</Link>
          </EventCardContainer>
        </div>
        <EventGrid>
          {events.data.map((ev) => (
            <EventCard event={ev} key={ev.id} />
          ))}
        </EventGrid>
      </Container>
    );
  }

  return (
    <Container>
      <p>loading...</p>
    </Container>
  );
};

export default EventPicker;

const EventGrid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  /* grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); */
  gap: 20px;
`;

const Container = styled.div`
  /* display: grid; */
  /* place-content: center; */
  width: 100%;
  height: 100%;

  p {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Button = styled.button<{ primary?: boolean }>`
  border: none;
  background: none;
  padding: 8px 32px;
  background-color: ${(p) =>
    p.primary ? p.theme.colors.red : p.theme.colors.grey};
  color: ${(p) => p.theme.colors.bg};
  border-radius: 8px;
  cursor: pointer;
`;

interface EventCardProps {
  event: Event;
}

const EventCard = ({ event }: EventCardProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const clear = useMutation((id: number) => clearScores(id));
  const handleClear = async () => {
    await clear.mutateAsync(event.id);
    setShowOverlay(false);
  };
  return (
    <EventCardContainer>
      {showOverlay && (
        <ConfirmOverlay
          loading={clear.isLoading}
          confirm={handleClear}
          cancel={() => setShowOverlay(false)}
        />
      )}
      <h1 style={{ marginBottom: '12px' }}>{event.name}</h1>
      <p style={{ textAlign: 'start' }}>
        Event ID: <strong>{event.id}</strong>
      </p>
      <ButtonContainer>
        <Link to={`/board/${event.id}${event.legacy ? '?new=0' : ''}`}>
          view
        </Link>
        <Link to={`/edit/${event.id}`}>edit</Link>
        {!event.legacy && <Link to={`/report/${event.id}`}>report</Link>}
        {event.legacy && (
          <Button onClick={() => setShowOverlay(true)}>clear scores</Button>
        )}
      </ButtonContainer>
    </EventCardContainer>
  );
};

export const EventCardContainer = styled.div`
  background-color: ${(p) => p.theme.colors.greyPale};
  padding: 32px;
  border-radius: 24px;
  /* margin-bottom: 32px; */
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    border: none;
    background: none;
    padding: 8px 32px;
    background-color: ${(p) => p.theme.colors.red};
    color: ${(p) => p.theme.colors.bg};
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    display: block;
  }

  > a {
    align-self: flex-start;
  }
`;

interface ConfirmProps {
  cancel: () => void;
  confirm: () => void;
  loading: boolean;
}

const clearScores = async (eventId: number) => {
  const { error } = await supabase
    .from(Table.SCORE)
    .delete()
    .eq('event_id', eventId);

  if (error) throw new Error(error.message);
};

const ConfirmOverlay = ({ cancel, confirm, loading }: ConfirmProps) => {
  return (
    <Overlay>
      <p>this cannot be undone, are you sure?</p>
      <ButtonContainer>
        <Button disabled={loading} onClick={confirm} primary>
          {loading ? 'clearing...' : 'delete'}
        </Button>
        <Button disabled={loading} onClick={cancel}>
          cancel
        </Button>
      </ButtonContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(20, 20, 20, 0.8);
  backdrop-filter: blur(4px);
  color: ${(p) => p.theme.colors.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: inherit;
`;
