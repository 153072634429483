import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { supabase } from '../db/client';
import { Score } from '../db/models';

import ScoreCard from './ScoreCard';

const Scoreboard = ({ eventId }: { eventId: number }): JSX.Element => {
  const [scores, setScores] = useState<Score[]>([]);

  const fetchScores = async () => {
    const { data, error } = await supabase
      .from('score')
      .select('id, name, score, mode')
      .eq('event_id', eventId)
      .order('score', { ascending: false });
    setScores(data as unknown as Score[]);
    if (error) console.error(error);
  };

  useEffect(() => {
    supabase
      .from('score')
      .select('id, name, score, mode')
      .order('score', { ascending: false })
      .eq('event_id', eventId)
      .then((res) => {
        console.log(res);
        setScores(res.data as unknown as Score[]);

        const listen = supabase
          .channel('public:score')
          .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: 'score' },
            (payload) => {
              console.log('Change received!', payload);
              fetchScores();
            }
          )
          .subscribe();
        console.log(listen);
      });
  }, []);
  return (
    <ScoreboardContainer layout>
      {scores &&
        scores.map((s, i) => <ScoreCard key={s.id} score={s} rank={i + 1} />)}
    </ScoreboardContainer>
  );
};

export default Scoreboard;

const ScoreboardContainer = styled(motion.div)`
  width: 100%;
  min-height: 100%;
  /* height: 100%;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
