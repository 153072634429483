import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Session } from '@supabase/supabase-js';

import { supabase } from '../db/client';
import { Event, GameMode, GameModes, Table } from '../db/models';
import { Button, EventCardContainer } from './EventPicker';
import { MAX_LENGTH } from './clients/ClaimScore';

const AddScore = ({ authSession }: { authSession: Session }): JSX.Element => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [score, setScore] = useState<number | string>();
  const [event, setEvent] = useState<Event>();
  const [gameMode, setGameMode] = useState<GameMode>('Mind');
  const [skin, setSkin] = useState<string>('');

  useEffect(() => {
    supabase
      .from(Table.EVENT)
      .select('*')
      .order('created_at', {
        ascending: false,
      })
      .then((res) => {
        if (res.data) {
          setEvents(res.data);
          setEvent(res.data[0]);
        }
      });
  }, []);

  const onSuccess = useCallback(() => {
    if (name && score) {
      toast.success('Succesfully added score!');
      setName('');
      setScore('');
    }
  }, [name, score]);

  const onSubmit = useCallback(async () => {
    if (name && score && event) {
      if (name.length > 0 && +score > 0) {
        setLoading(true);
        const { error } = event.legacy
          ? await supabase.from(Table.SCORE).insert({
              name,
              score,
              event_id: event.id.toString(),
            })
          : await supabase.from(Table.POUR).insert({
              name,
              score,
              claimed: true,
              completed: true,
              mode: gameMode,
              skin,
              difficulty: 'easy',
              account_id: authSession.user.id,
              event_id: event.id.toString(),
            });
        if (error) {
          toast.error(error.message);
        } else {
          onSuccess();
        }
      } else {
        toast.error('Please fill in all fields');
      }
      setLoading(false);
    } else {
      toast.error('Please fill in all fields');
    }
  }, [name, score, event, gameMode, skin, onSuccess]);

  return (
    <>
      <Link to="/">
        <Button>Back to menu</Button>
      </Link>
      <Container>
        <Inner>
          <h1>Add Score</h1>
          <div>
            <p>Event</p>
            <select
              value={event?.id}
              onChange={(e) =>
                setEvent(
                  events.find((event) => `${event.id}` === e.target.value)
                )
              }
              name="Event"
              placeholder="event"
            >
              {events.map((ev) => (
                <option key={ev.id} value={ev.id}>
                  {ev.name}
                </option>
              ))}
            </select>
          </div>
          {event?.legacy === false && (
            <div>
              <label htmlFor="gameMode">Game mode</label>
              <select
                id="gameMode"
                value={`${gameMode}-${skin}`}
                onChange={(e) => {
                  const [mode, newSkin] = e.target.value.split('-');
                  setGameMode(mode as GameMode);
                  setSkin(newSkin);
                }}
              >
                {GameModes.map((m) => (
                  <option key={m.type} value={`${m.type}-${m.skin ?? ''}`}>
                    {m.title}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <p>Participant name</p>
            <input
              value={name}
              maxLength={MAX_LENGTH}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </div>
          <div>
            <p>Score</p>
            <input
              type="number"
              value={score}
              onChange={(e) =>
                setScore(
                  e.target.value === '' ? undefined : e.target.valueAsNumber
                )
              }
              placeholder="Score"
            />
          </div>
          <Button primary onClick={onSubmit}>
            {loading ? 'submitting...' : 'Submit'}
          </Button>
        </Inner>
      </Container>
    </>
  );
};

export default AddScore;

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
`;

export const Inner = styled(EventCardContainer)`
  gap: 16px;

  input,
  select {
    border-radius: 4px;
    min-height: 32px;
    padding: 4px;
    margin-top: 4px;
    width: 100%;
  }
`;
